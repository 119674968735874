import { LayoutSingleColumn, ListingCard, MobileBottomNavigation, Page } from '../../components';
import ListingsSection from '../CustomLandingPage/components/ListingsSection/ListingsSection';
import FooterContainer from '../FooterContainer/FooterContainer';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import HeroCollectionSection from './components/HeroCollectionSection/HeroCollectionSection';
import { HowItWorks } from './components/HowItWorks/HowItWorks';
import PiferiCustomSection from './components/PiferiCustomSection/PiferiCustomSection';
import ShowcaseCarousel from './components/ShowcaseCarousel/ShowcaseCarousel';
import { I } from './PiferiCustomI';
import { ConditionType } from 'config/configListing';
import appSettings from 'config/settings';
import LandingSection from 'containers/CustomLandingPage/components/LandingSection/LandingSection';
import { useGetListings } from 'hooks/api/listings/useGetListings';
import { useIsScrollingDisabled } from 'hooks/selectors/useIsScrollingDisabled';
import { Listing } from 'models/ListingModels';
import { DEFAULT_SORTING } from 'util/constants';

export const PIFERI_BRAND_ID = appSettings.dev ? 7865 : 7864;

const PiferiLandingPage = () => {
  const scrollingDisabled = useIsScrollingDisabled();

  const { data: listingsData } = useGetListings({
    brandIds: [PIFERI_BRAND_ID],
    condition: ConditionType.SampleSale,
  });
  const allListings: Listing[] = listingsData?.pages.flatMap(page => page.listings) || [];
  const latestListings = allListings.slice(0, 24);

  return (
    <Page title="THE NOLD" scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn topbar={<TopbarContainer />} footer={<MobileBottomNavigation />}>
        <div className="flex flex-col items-center justify-start w-full">
          <HeroCollectionSection />
          <LandingSection>
            <div className="grid gap-5 w-full max-w-screen-xl mx-auto px-3 md:px-10">
              <h1 className="font-syne text-4 md:text-6 font-bold uppercase">
                P<I />
                feri circular
              </h1>
              <p className="font-montserrat text-1 md:text-2">
                PIFERI CIRCULAR, in collaboration with NOLD, introduces a sanctuary where luxury
                meets legacy through exclusive sample items and remarkable discounts. Enjoy PIFERI
                's exquisite, sustainable designs while nurturing our planet.
                <br />
                <br />
                We dare to make a difference. Do you?
              </p>
            </div>
          </LandingSection>
          <PiferiCustomSection />
          <ShowcaseCarousel
            title="Top sample collections"
            items={[
              {
                title: <span className="text-white">Flats</span>,
                imgSrc:
                  'https://cdn.thenold.com/landing-pages/piferi/Drizella Flat Tiger Satin_compressed.webp',
                imgBlurSrc:
                  'https://cdn.thenold.com/landing-pages/piferi/Drizella Flat Tiger Satin_blur.webp',
                linkParams: {
                  name: 'SearchPage',
                  to: {
                    search: `brandIds=${PIFERI_BRAND_ID}&condition=${ConditionType.SampleSale}&category=shoes&productType=espadrilles,mules-%26-clogs,sandals,ballerinas&sortBy=${DEFAULT_SORTING}`,
                  },
                },
              },
              {
                title: <span className="text-white">Heels</span>,
                imgSrc:
                  'https://cdn.thenold.com/landing-pages/piferi/Lotta 70 Satin Iris Blue 2_compressed.webp',
                imgBlurSrc: 'https://cdn.thenold.com/landing-pages/piferi/',
                linkParams: {
                  name: 'SearchPage',
                  to: {
                    search: `brandIds=${PIFERI_BRAND_ID}&condition=${ConditionType.SampleSale}&category=shoes&productType=heels&sortBy=${DEFAULT_SORTING}`,
                  },
                },
              },
              {
                title: <span className="text-white">Boots</span>,
                imgSrc:
                  'https://cdn.thenold.com/landing-pages/piferi/MIRAGE 100 FLASH TECHNO IRIS BLUE_3_compressed.webp',
                imgBlurSrc:
                  'https://cdn.thenold.com/landing-pages/piferi/MIRAGE 100 FLASH TECHNO IRIS BLUE_3_blur.webp',
                linkParams: {
                  name: 'SearchPage',
                  to: {
                    search: `brandIds=${PIFERI_BRAND_ID}&condition=${ConditionType.SampleSale}&category=shoes&productType=boots&sortBy=${DEFAULT_SORTING}`,
                  },
                },
              },
            ]}
            itemClassName="aspect-w-1 aspect-h-1 w-3/4 max-w-[600px] sm:w-3/5 md:w-[45%] lg:w-1/3"
          />
          {Boolean(latestListings?.length) && (
            <ListingsSection
              title={
                <>
                  P<I />
                  feri Archive
                </>
              }
            >
              {latestListings.map(l => (
                <ListingCard key={l.id} listing={l} />
              ))}
            </ListingsSection>
          )}
          <LandingSection title="How it works">
            <HowItWorks />
          </LandingSection>
        </div>
        <FooterContainer />
      </LayoutSingleColumn>
    </Page>
  );
};

export default PiferiLandingPage;
